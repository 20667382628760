import OauthProtectedService from "../base/OauthProtectedService";

class IdentityService extends OauthProtectedService {

    getUsers = (size = 10) => {
        return fetch(this.getCaptnBaseUrl() + "/identity/users?size=" + size, {
            method: "GET",
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': "application/json",
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.json();
        }).then(function (userResponse) {
            return userResponse;
        });
    };

    getUser = (userSettingsId) => {
        return fetch(this.getCaptnBaseUrl() + "/identity/users/" + userSettingsId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (userResponse) {
            return userResponse;
        });
    };

    getMe = () => {
        return fetch(this.getCaptnBaseUrl() + "/identity/users/me", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (userResponse) {
            return userResponse;
        });
    }

    createOrganisationAdmin = (data) => {
        return fetch('/api/organisations/admin-account', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            return (myJson);
        });
    };

    /**
     * Returns information about the currently logged in user
     * @returns {{firstName: string, lastName: string, jobTitle: string, email: string}}
     */
    getLoggedInUser = () => {
        return {
            firstName: sessionStorage.getItem('firstName') || localStorage.getItem('firstName'),
            lastName: sessionStorage.getItem('lastName') || localStorage.getItem('lastName'),
            email: sessionStorage.getItem('email') || localStorage.getItem('email'),
            jobTitle: sessionStorage.getItem('jobTitle') || localStorage.getItem('jobTitle'),
            userId: sessionStorage.getItem('userId') || localStorage.getItem('userId')
        }
    }

    login = (email, password) => {
        const captnBaseUrl = this.getCaptnBaseUrl();

        return fetch('/api/authenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            })
        }).then(function (response) {
            return response.json();
        }).then(function (authenticationResponse) {
            if (authenticationResponse.error !== "invalid_grant") {
                return fetch(captnBaseUrl + "/identity/users/me", {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + authenticationResponse.access_token,
                    }
                }).then(function (response) {
                    return response.json();
                }).then(function (userResponse) {
                    localStorage.setItem('accessToken', authenticationResponse.access_token);
                    localStorage.setItem('organisationId', authenticationResponse.organisationId);
                    localStorage.setItem('userClientId', authenticationResponse.userClientId);
                    localStorage.setItem('userId', authenticationResponse.userId);
                    localStorage.setItem('firstName', userResponse.firstName);
                    localStorage.setItem('lastName', userResponse.lastName);
                    localStorage.setItem('email', userResponse.email);
                    localStorage.setItem('jobTitle', userResponse.jobTitle);
                });
            }
        });
    };
}

const authService = new IdentityService();

export default authService;
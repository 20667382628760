import OauthProtectedService from "../base/OauthProtectedService";

class OrganisationService extends OauthProtectedService {
    getOwnOrganisation = () => {
        return fetch(this.getCaptnBaseUrl() + "/organisation/organisations/me", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + this.getAuthToken(),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(function(response) {
                return response.json();
            })
            .then(function(organisationMeResponse) {
                return organisationMeResponse;
            });
    };
}

const organisationService = new OrganisationService();

export default organisationService;

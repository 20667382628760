import {createMuiTheme} from "@material-ui/core/styles";

export const getMuiTheme = (theme) => createMuiTheme({
    palette: {
        type: theme.mode,
        primary: {
            main: '#fff',
        },
        secondary: {
            main: "#00c853"
        }
    }
});
import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import DynamicComponent from "../../dynamic/DynamicComponent";

const SimpleNavigation = ({links}) => {
    return (
        <>
            {
                links.map((linkItem, idx) => {
                    if (typeof linkItem.component !== "undefined") {
                        return <DynamicComponent key={idx} component={linkItem.component} props={linkItem.props}
                                                 position={linkItem.position}/>;
                    }

                    return <Link to={linkItem.path} key={linkItem.path}>
                        <Button aria-controls="simple-menu" aria-haspopup="false">
                            {linkItem.title}
                        </Button>
                    </Link>
                })
            }
        </>
    );
};

export default SimpleNavigation;

import OauthProtectedService from "../base/OauthProtectedService";

class JobService extends OauthProtectedService {
    /**
     *
     * @param size
     * @param filters can have the following keys assignments, projectUrns
     * @returns {Promise<any>}
     */
    getJobs = (size = 10, filters = {}) => {
        let filterUrlPart = this.buildUrlPart(filters);

        return fetch(this.getCaptnBaseUrl() + '/job/jobs?size=' + size + filterUrlPart, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (jobs) {
            return jobs;
        });
    };

    getJob = (jobUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/job/jobs/' + jobUrn, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            return response;
        });
    };

    getJobsTypes = () => {
        return fetch(this.getCaptnBaseUrl() + '/job/types?size=5000', {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (modelResponse) {
            return modelResponse["_embedded"]["jobTypeResourceList"];
        });
    };

    getJobContactTypes = () => {
        return fetch(this.getCaptnBaseUrl() + '/job/contact-types?size=5000', {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (modelResponse) {
            return modelResponse["_embedded"]["jobContactTypeResourceList"];
        });
    };

    createJob = (jobToSave) => {
        return fetch(this.getCaptnBaseUrl() + '/job/jobs', {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jobToSave)
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            return response;
        });
    };

    updateJob = (jobToSave) => {
        return fetch(this.getCaptnBaseUrl() + '/job/jobs/' + jobToSave["jobUrn"], {
            method: 'PUT',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jobToSave)
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            return response;
        });
    };

    deleteJob = (jobUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/job/jobs/' + jobUrn, {
            method: 'DELETE',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    };

    updateJobOrder = (jobToSave, previousJobUrn, nextJobUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/job/jobs/' + jobToSave["jobUrn"] + "/task-order", {
            method: 'PUT',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                jobStatus: jobToSave["status"],
                previousJobUrn: previousJobUrn,
                nextJobUrn: nextJobUrn
            })
        }).then(function (response) {
            return response.json();
        }).then(function (response) {
            return response;
        });
    };
}

const jobService = new JobService();

export default jobService;
import compositionService from "./services/CompositionService";
import identityService from "./services/IdentityService";
import itemService from "./services/ItemService";
import jobService from "./services/JobService";
import modelService from "./services/ModelService";
import orderService from "./services/OrderService";
import organisationService from "./services/OrganisationService";
import projectService from "./services/ProjectService";
import routingService from "./services/RoutingService";

class CaptnApiClient {

    constructor() {
        this.composition = compositionService;
        this.identity = identityService;
        this.item= itemService;
        this.job = jobService;
        this.model = modelService;
        this.order = orderService;
        this.organisation = organisationService;
        this.project = projectService;
        this.routing = routingService;
    }
}

const captnApiClient = new CaptnApiClient();
export default  captnApiClient;
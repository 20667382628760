import OauthProtectedService from "../base/OauthProtectedService";

class OrderService extends OauthProtectedService {
    getOrders = (pageOptions = {page: 0, size: 10}, filters = {}, sort = {}) => {
        const filterUrlPart = this.buildUrlPart(filters);
        const sortUrlPart = this.buildUrlPart(sort);
        const paginationUrlPart = this.buildPaginationUrlPart(pageOptions);

        // return fetch('http://localhost:5016/orders'  + paginationUrlPart + filterUrlPart, {
        return fetch(this.getCaptnBaseUrl() + '/order/orders?' + paginationUrlPart + filterUrlPart + sortUrlPart, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        })
    };
    getOrder = (orderUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/order/orders/' + orderUrn, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        });
    };

    createOrder = (data) => {
        // return fetch('http://localhost:5016/orders', {
        return fetch(this.getCaptnBaseUrl() + '/order/orders', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + this.getAuthToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            console.log(myJson);
            return (myJson);
        });
    };

    updateOrder = (data) => {
        return fetch(this.getCaptnBaseUrl() + '/order/orders/' + data["orderUrn"], {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + this.getAuthToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            console.log(myJson);
            return (myJson);
        });
    };

    getPayment = (orderUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/order/orders/' + orderUrn + "/payment", {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        });
    };

    parseResponseBody(data) {
        return data["_embedded"] !== undefined ? data["_embedded"]["orderResponseList"] : [];
    }
}

const orderService = new OrderService();

export default orderService;
import OauthProtectedService from "../base/OauthProtectedService";

class CompositionService extends OauthProtectedService {
    getCompositions = () => {
        return fetch(this.getCaptnBaseUrl() + '/composition/models', {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (compositionResponse) {
            let res = compositionResponse["_embedded"] !== undefined ? compositionResponse["_embedded"]["modelConfigResponseList"] : []
            return res;
        });
    };

    getCompositionsByUrns = (compositionUrns) => {
        return fetch(this.getCaptnBaseUrl() + '/composition/models?urns=' + compositionUrns.join(','), {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (compositionResponse) {
            return compositionResponse;
        });
    };

    getCompositionsForModels = (modelIds) => {
        return fetch(this.getCaptnBaseUrl() + '/composition/models?modelIds=' + modelIds, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (compositionResponse) {
            let res = compositionResponse["_embedded"] !== undefined ? compositionResponse["_embedded"]["modelConfigResponseList"] : []
            return res;
        });
    };

    getComposition = (compositionUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/composition/models/' + compositionUrn, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        });
    };

    createComposition = (data) => {
        // return fetch('http://localhost:5008/models', {
        return fetch(this.getCaptnBaseUrl() + '/composition/models', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                "Authorization": "Bearer " + this.getAuthToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            console.log(myJson);
            return (myJson);
        });
    };

    parseResponseBody(data) {
        return data["_embedded"] !== undefined ? data["_embedded"]["modelConfigResponseList"] : [];
    }
}

const compositionService = new CompositionService();

export default compositionService;
import jwt from "jsonwebtoken";

/**
 * A abstract implementation providing the means to get the bearerToken for the currently logged in user.
 * The login and setting of this token is done by the Aut AuthenticationService
 */
export default class OauthProtectedService {
    //TODO: Get refresh token for a user where the token is expired
    getAuthToken = () => {
        return localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
    };

    getCaptnBaseUrl = () => {
        return process.env.REACT_APP_CAPTN_API_ENDPOINT;
    };

    cleanLocalStorageData = () => {
        localStorage.clear();
    };

    checkToken = token => {
        let valid = false;
        try {
            valid = Date.now() / 1000 < jwt.decode(token).exp;
        } catch (e) {
            console.error(e);
        }
        return valid;
    };

    isAuthenticated = () => {
        const token = this.getAuthToken();
        if (token !== undefined) {
            if (token && this.checkToken(token)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    buildPaginationUrlPart = (pageOptions) => {
        return "page=" + pageOptions.page + "&size=" + pageOptions.size
    };

    buildUrlPart = (urlObject) => {
        let filterUrlPart = "";

        Object.keys(urlObject).forEach((key) => {
            const value = urlObject[key];
            if (value !== null && value.length > 0) {
                if(filterUrlPart !== "") {
                    filterUrlPart += "&";
                }
                let valueForFilter = Array.isArray(value) ? value.join(',') : value;
                filterUrlPart += "&" + key + "=" + valueForFilter;
            }
        });

        return filterUrlPart;
    }
}
import React from "react";
import DynamicComponent from "../../dynamic/DynamicComponent";
import styled from "styled-components";

const FixedContainer = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem 1rem;

    @media (min-width: 768px) {
        padding-left: 0rem;
        padding-right: 0rem;
    }  
`;

const Container = styled.div`
    max-width: ${props => props.width || "80rem"};
    margin: 0 auto;
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const FooterItem = styled.div`
    margin-left: 1rem;
`;


const Footer = ({width, items = []}) => {

    function Items({items}) {
        return <>
            {
                items.map((child, idx) => {
                    return <FooterItem key={idx}><DynamicComponent key={idx} component={child.component} props={child.props} position={child.position}/></FooterItem>;
                })
            }
        </>;
    }

    return (
        <FixedContainer>
            <Container width={width}>
                <Row>
                    <Items items={items} />
                </Row>
            </Container>
        </FixedContainer>
    );
};

export default Footer;

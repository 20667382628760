import React from 'react'

function ErrorPage() {
    return (

        <div>
            <h1 className="errorTitle">ERROR</h1>
            <p>We're terribly sorry. The route you tried to access does not seem to exist.</p>
            <span className="btn">Go back</span>
        </div>
        );
}

export default ErrorPage;
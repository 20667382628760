import OauthProtectedService from "../base/OauthProtectedService";

class ModelService extends OauthProtectedService {
    getModels = (pageOptions = {page: 0, size: 10}) => {
        let paginationUrlPart = this.buildPaginationUrlPart(pageOptions);

        return fetch( this.getCaptnBaseUrl() + '/model/models?' + paginationUrlPart, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (modelResponse) {
            return modelResponse;
        });
    };

    getModelsByIds = (modelIds) => {
        return fetch(this.getCaptnBaseUrl() + '/model/models?ids=' + modelIds.join(","), {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (modelResponse) {
            return modelResponse["_embedded"]["modelResponses"];
        });
    };

    getModel = (modelId) => {
        return fetch(this.getCaptnBaseUrl() + '/model/models/' + modelId, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (modelResponse) {
            return modelResponse;
        });
    };

    parseResponseBody = (body) => {
        return typeof body["_embedded"] !== "undefined" ? body["_embedded"]["modelResponses"] : [];
    }
}

const modelService = new ModelService();

export default modelService;
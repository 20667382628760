import OauthProtectedService from "../base/OauthProtectedService";

class RoutingService extends OauthProtectedService {
    getRoute = (routeUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/routing/routes?urn=' + routeUrn, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (modelResponse) {
            return modelResponse["_embedded"]["routingResourceList"][0];
        });
    };
}

const routingService = new RoutingService();

export default routingService;
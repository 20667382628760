import React from "react";
import DynamicComponent from "../../dynamic/DynamicComponent";
import styled from "styled-components";

const Container = styled.div`
    max-width: ${props => props.width || "80rem"};
    margin: 0 auto;
`;

const Row = styled.section`
    display: flex;
`;

const Col = styled.div`
    width: ${props => props.mobile || "100%"};
    padding: 0 1rem;

    @media (min-width: 768px) {
        width: ${props => props.desktop || "100%"};
        padding: 0rem;
    }       
`;

const Grid = ({width, rows = []}) => {
    const getColumnChildren = (column) => {
        return column.children.map((child, idx) => {
            return <DynamicComponent key={idx} component={child.component} props={child.props} position={child.position}/>;
        });
    };

    const createColumns = (row) => {
        return row.columns.map((column, idx) => {
            return <Col key={idx} {...column.props}>{getColumnChildren(column)}</Col>;
        });
    };

    function ContainerRows({rows}) {
        return <>
            {
                rows.map((row, idx) => {
                    return <Row key={idx}>{createColumns(row)}</Row>
                })
            }
        </>;
    }

    return (
        <Container width={width}>
            <ContainerRows rows={rows}/>
        </Container>
    );
};

export default Grid;

import React from "react";
import DynamicComponent from "./DynamicComponent";

function DynamicPage({pageConfig}) {
    function generateDynamicComponent(content, idx) {
        let position = typeof content.position !== "undefined" ? content.position : "default";
        let children = typeof content.children !== "undefined" ? content.children : [];

        return <DynamicComponent key={idx} component={content.component} props={content.props} position={position}>
            {children.map((child) => generateDynamicComponent(child))}
        </DynamicComponent>;
    }

    return (
        <>
            {
                pageConfig.content.map((content, idx) => {
                    return generateDynamicComponent(content, idx);
                })
            }
        </>
    );
}

export default React.memo(DynamicPage);

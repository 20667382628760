export const lightThemeVariables = {
    navBg: "white",
    navLogoHeight: "6rem",
    navPaddingTop: "4rem",
    navPaddingBottom: "7rem",
    navContainer: "56rem",
    navBorderActive: "transparent",
    navBorderHover: "transparent",
    navColorHover: "black",
    navColor: "black",
    navHeight: "6rem",
    navLinkVertical: "flex-end",
    navLinkPaddingBottom: "1rem",
    navTextDecoration: "underline",
    navLastChildMargin: "0.25rem"
};

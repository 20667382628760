import OauthProtectedService from "../base/OauthProtectedService";

class ItemService extends OauthProtectedService {
    getItems = () => {
        return fetch(this.getCaptnBaseUrl() + '/item/items/', {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (itemResponse) {
            let res = itemResponse["_embedded"] !== undefined ? itemResponse["_embedded"]["itemResponseList"] : []
            return res;
        });
    };
    getItem = (itemUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/item/items/' + itemUrn, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        });
    };
}

const itemService = new ItemService();

export default itemService;
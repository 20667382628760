import React from "react";
import RawHtml from "../components/UI/RawHtml";
import SimpleNavigation from "../components/Navigation/SimpleNavigation";
import TopNavigation from "../components/Navigation/TopNavigation";
import Grid from "../components/UI/Grid";
import Image from "../components/UI/Image";
import Button from "../components/UI/Button";
import styled from "styled-components";
import Footer from "../components/UI/Footer";

const components = {
    html: RawHtml,
    image: Image,
    button: Button,
    topNavigation: TopNavigation,
    simpleNavigation: SimpleNavigation,
    grid: Grid,
    footer: Footer
};

const Centered = styled.div`
    text-align: center;
`;

function DynamicComponent({component, props, position, children}) {
    const Component = components[component];

    if (position === "center") {
        return <Centered>
            <Component {...props} >{children}</Component>
        </Centered>
    }

    return <Component {...props} >{children}</Component>;
}

export default React.memo(DynamicComponent);

import React, {useState} from "react";
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DynamicComponent from "../../dynamic/DynamicComponent";
import {useLocation} from 'react-router-dom'

const defaultVariables = {
    navLogoHeight: "2rem",
    navPaddingTop: "1rem",
    navPaddingBottom: "1rem",
    navContainer: "80rem",
    navHeight: "4rem",
    navLinkVertical: "center",
    navLinkPaddingBottom: "0px",
    navTextDecoration: "none",
    navLastChildMargin: "2rem"
};

const darkVariables = {
    navBg: "#252f3f",
    navMobileBg: "#161e2e",
    navBorderActive: "#d2d6dc",
    navBorderHover: "white",
    navColorHover: "white",
    navColor: "#d2d6dc",
};

const lightVariables = {
    navBg: "#f4f5f7",
    navMobileBg: "white",
    navBorderActive: "#d2d6dc",
    navBorderHover: "#d2d6dc",
    navColorHover: "#374151",
    navColor: "#6b7280",
};

function getDefaultValueForTheme(theme, key) {
    let defaultValue = typeof defaultVariables[key] !== "undefined" ? defaultVariables[key] : null;
    if (defaultValue === null) {
        defaultValue = theme.mode === "dark" ? darkVariables[key] : lightVariables[key];
    }

    return `var(--${key}, ${defaultValue})`;
}

const Navbar = styled.nav` 
    background: ${props => getDefaultValueForTheme(props.theme, 'navBg')};
`;

const Container = styled.div` 
    max-width: ${props => getDefaultValueForTheme(props.theme, 'navContainer')};
    margin: 0 auto;

    @media (min-width: 768px) {
        padding-top: ${props => getDefaultValueForTheme(props.theme, 'navPaddingTop')};
        padding-bottom: ${props => getDefaultValueForTheme(props.theme, 'navPaddingBottom')};
    }

    @media (max-width: 767px) {
        padding: 1rem;
    }
`;

const NavContainer = styled.div` 
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${props => getDefaultValueForTheme(props.theme, 'navHeight')};
`;

const Nav = styled.div` 
    @media (min-width: 768px) {
        display: ${props => props.mobile ? "none" : "flex"};
    }

    @media (max-width: 767px) {
        display: ${props => props.desktop ? "none" : "flex"};
    }   

`;

const Logo = styled.div` 
    align-items: center;
    flex-shrink: 0;
    display: flex;
`;

const LogoImg = styled.img` 
    width: auto;
    height: ${props => getDefaultValueForTheme(props.theme, 'navLogoHeight')};
`;

const NavLinks = styled.div` 
    display: flex;     
    padding-bottom: ${props => getDefaultValueForTheme(props.theme, 'navLinkPaddingBottom')};
`;

const NavLink = styled.a` 
    height: ${props => getDefaultValueForTheme(props.theme, 'navHeight')};
    color: ${props => props.active ? getDefaultValueForTheme(props.theme, 'navColorHover') : getDefaultValueForTheme(props.theme, 'navColor')};
    text-decoration: ${props => props.active ? getDefaultValueForTheme(props.theme, 'navTextDecoration') : "none"};
    margin-left: 2rem;
    display: inline-flex;
    align-items: ${props => getDefaultValueForTheme(props.theme, 'navLinkVertical')};
    padding: 0.25rem 0 0;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: ${props => props.active ? getDefaultValueForTheme(props.theme, 'navBorderActive') : "transparent"};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;

    &:hover {
        color: ${props => getDefaultValueForTheme(props.theme, 'navColorHover')};
        border-color: ${props => getDefaultValueForTheme(props.theme, 'navBorderHover')};
        text-decoration: ${props => getDefaultValueForTheme(props.theme, 'navTextDecoration')};
    }

    &:focus {
        color: ${props => getDefaultValueForTheme(props.theme, 'navColorHover')};
        border-color: ${props => getDefaultValueForTheme(props.theme, 'navBorderHover')};
        text-decoration: ${props => getDefaultValueForTheme(props.theme, 'navTextDecoration')};
        outline: none;
    }

    &:last-child {
        margin-left: ${props => getDefaultValueForTheme(props.theme, 'navLastChildMargin')};
    }
 
`;

const MobileContainer = styled.div` 
    padding-top: .5rem;
    padding-bottom: .75rem;
    background: ${props => getDefaultValueForTheme(props.theme, 'navMobileBg')};
    @media (min-width: 768px) {
        display: none;
    }        
`;

const MobileNavLink = styled.a` 
    display: block;

    padding-left: .75rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    color: ${props => getDefaultValueForTheme(props.theme, 'navColor')};
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;        

    &:hover {
        color: ${props => getDefaultValueForTheme(props.theme, 'navColorHover')};
        background: ${props => getDefaultValueForTheme(props.theme, 'navBg')};
        border-color: ${props => getDefaultValueForTheme(props.theme, 'navBorderHover')};
        text-decoration: ${props => getDefaultValueForTheme(props.theme, 'navTextDecoration')};
    }

    &:focus {
        color: ${props => getDefaultValueForTheme(props.theme, 'navColorHover')};
        background: ${props => getDefaultValueForTheme(props.theme, 'navBg')};            
        border-color: ${props => getDefaultValueForTheme(props.theme, 'navBorderHover')};
        text-decoration: ${props => getDefaultValueForTheme(props.theme, 'navTextDecoration')};
        outline: none;
    }        
`;

/**
 * Component that is responsible for rendering the main top navigation.
 *
 * @param logo: url of the logo to display
 * @param primaryLinks: Array of links that is shown on the middle of the navigation menu.
 * The links can consists of
 * @example
 * "primaryLinks": [
 * {
 *  "type": "link",
 *  "path": "/",
 *  "title": "Home"
 * },
 * {
 *  "type": "component",
 *  "path": "/shop",
 *  "component": "loginLink"
 * }
 * ]
 * @param secondaryLinks is an array of links that will be shown on the right of the navigation bar.
 * @returns {*}
 *
 * Theming: Can make use of the following css variables to overwrite defaults:
 *
 * - navBg
 * - navMobileBg
 * - navBorderActive
 * - navBorderHover
 * - navColorHover
 * - navColor
 *
 */
const TopNavigation = ({logo = "", primaryLinks = [], secondaryLinks = []}) => {
    const [hidden, setHidden] = useState(true);
    const location = useLocation();

    /**
     * Internal method used to render a single link item. This can be a normal link or a
     * component (Mostly an icon).
     * @param link item
     * @param mobile boolean
     * @returns {*}
     * @constructor
     */
    const SingleLink = ({link, mobile}) => {
        const isActive = location.pathname === link.path;

        if (link.type === "link") {
            if (mobile) {
                return <MobileNavLink active={isActive}
                                      href={link.path}>{link.type === "link" ? link.title : link.component}</MobileNavLink>;
            }
            return <NavLink active={isActive}
                            href={link.path}>{link.type === "link" ? link.title : link.component}</NavLink>;
        } else if (link.type === "component") {
            return <DynamicComponent component={link.component} props={link.props}/>;
        }

        return <React.Fragment/>;
    }

    let secondaryTag;
    if (secondaryLinks.length > 0) {
        secondaryTag =
            (<Nav desktop>
                <NavLinks>
                    {
                        secondaryLinks.map((link) => {
                            return <SingleLink key={link.path} link={link} mobile={false}/>;
                        })
                    }
                </NavLinks>
            </Nav>);
    } else {
        secondaryTag = '';
    }

    return (
        <Navbar>
            <Container>
                <NavContainer>
                    {
                        logo !== "" ? <Logo><LogoImg src={logo}/></Logo> : <React.Fragment/>
                    }
                    <Nav desktop>
                        <NavLinks>
                            {
                                primaryLinks.map((link) => {
                                    return <SingleLink key={link.path} link={link} mobile={false}/>;
                                })
                            }
                        </NavLinks>
                    </Nav>

                    {secondaryTag}
                    <Nav mobile>
                        <NavLinks>
                            <span onClick={() => setHidden(!hidden)}><IconButton><MenuIcon/></IconButton></span>
                        </NavLinks>
                    </Nav>
                </NavContainer>
            </Container>
            <MobileContainer hidden={hidden}>
                {
                    primaryLinks.map((link) => {
                        return <SingleLink key={link.path} link={link} mobile={true}/>;
                    })
                }
            </MobileContainer>
        </Navbar>
    );
};

export default TopNavigation;


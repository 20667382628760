import OauthProtectedService from "../base/OauthProtectedService";

class ProjectService extends OauthProtectedService {
    getProjects = (pageOptions = {page: 0, size: 10}, filters = []) => {
        let filterUrlPart = this.buildUrlPart(filters);
        let paginationUrlPart = this.buildPaginationUrlPart(pageOptions);

        return fetch(this.getCaptnBaseUrl() + '/project/projects?' + paginationUrlPart + filterUrlPart, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': '*/*',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        }).then(function (projectResponse) {
            return projectResponse;
        });
    };
    getProject = (projectUrn) => {
        return fetch(this.getCaptnBaseUrl() + '/project/projects/' + projectUrn, {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + this.getAuthToken(),
                'Accept': '*/*',
                'Content-Type': '*/*'
            }
        }).then(function (response) {
            return response.json();
        });
    };

    parseResponseBody = (body) => {
        return typeof body["_embedded"] !== "undefined" ? body["_embedded"]["projectResponseList"] : []
    }
}

const projectService = new ProjectService();

export default projectService;
import React from "react";
import styled from "styled-components";

const CoverImage = styled.img`
    width: ${props => props.width};
    height: ${props => props.height};
    object-fit: cover;
    z-index:-1;
`;

const Image = ({src, alt, width, height, type = "default"}) => {
    if (type === "cover") {
        return <CoverImage src={src} width={width} height={height}/>
    }

    return <img src={src} alt={alt} width={width} height={height}/>
};

export default Image;

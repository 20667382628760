import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import PrivateRoute, {DefaultSecureRoutes} from "./routing/PrivateRoute";
import DynamicPage from "./dynamic/DynamicPage";
import {DefaultPublicRoutes} from "./routing/WebshopRoutes";
import useTheme from "./theming/useTheme";
import {GlobalStyles} from "./theming/themes/global";
import {ThemeProvider} from "styled-components";

function App() {
    const theme = useTheme();

    const [routerConfiguration, setRouterConfiguration] = useState({
        routes: [],
        privateRoutes: DefaultSecureRoutes
    });

    /**
     * Main function that parses the configuration and creates the routerConfiguration for it
     */
    useEffect(() => {
        let routes = routerConfiguration.routes;
        let privateRoutes = routerConfiguration.privateRoutes;

        fetch(`${process.env.PUBLIC_URL}/config/shop-config.json`)
            .then((r) => r.json())
            .then((config) => {
                config.routing.forEach(route => {
                    let page = config.pages.find((page) => page.key === route.page);
                    replaceComponentKeys(config, page);

                    const PageComponent = React.memo(() => <DynamicPage pageConfig={page}/>);
                    let routeToAdd = {
                        path: route.path,
                        exact: true,
                        component: PageComponent
                    };

                    if (route.secure) {
                        privateRoutes.push(routeToAdd);
                    } else {
                        routes.push(routeToAdd);
                    }
                });

                routes = routes.concat(DefaultPublicRoutes);

                setRouterConfiguration({
                    routes: routes,
                    privateRoutes: privateRoutes
                });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper function to lookup and replace the componentKey's by the components defined in the components section of the config.
     * The props defined on pageConfig level are merged with the default props provided in the component section of the config.
     *
     * TODO: Should become a recursive function in the future
     *
     * @param config
     * @param pageConfig
     */
    const replaceComponentKeys = (config, pageConfig) => {
        pageConfig.content = pageConfig.content.map((content) => {
            content = replaceSingleComponentKey(config, content);

            if (typeof content.children !== "undefined") {
                content.children = content.children.map((childContent) => {
                    return replaceSingleComponentKey(config, childContent);
                })
            }

            return content;
        });
    };

    /**
     * Helper function to replace a single componentKey
     * @see replaceComponentKeys
     * @param config
     * @param childContent
     * @returns {{component: *, position, props: *}|*}
     */
    function replaceSingleComponentKey(config, childContent) {
        if (childContent.componentKey != null) {
            const lookupComponent = config.components.find((component) => component.key === childContent.componentKey);
            let mergedProps = Object.assign({}, lookupComponent.props);

            if (typeof childContent.props !== "undefined") {
                Object.entries(childContent.props).forEach(([key, value]) => {
                    mergedProps[key] = value;
                });
            }

            return {
                component: lookupComponent.component,
                props: mergedProps,
                position: lookupComponent.position
            };
        }

        return childContent;
    }

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles theme={theme}/>
            <div className="app">
                <Router>
                    <Switch>
                        {
                            routerConfiguration.privateRoutes.map(
                                (route, index) =>
                                    <PrivateRoute key={index} {...route}/>
                            )
                        }
                        {
                            routerConfiguration.routes.map(
                                (route, index) =>
                                    <Route key={index} {...route}/>
                            )
                        }
                    </Switch>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;

import {useEffect, useState} from 'react';
import storage from 'local-storage-fallback';
import useWindowSize from "./useWindowSize";
import {getMuiTheme} from "./themes/muiTheme";
import {lightThemeVariables} from "./themes/lightThemeVariables";
import {darkThemeVariables} from "./themes/darkThemeVariables";

const defaultThemeSettings = {
    mode: 'light',
    values: lightThemeVariables,
    device: 'desktop',
    default: {}
};

/**
 * Will set the css variables on the root object.
 * @param theme is an object that represents the css variables to be used (key, value)
 */
export const setCSSVariables = theme => {
    // eslint-disable-next-line no-unused-vars
    for (const value in theme) {
        document.documentElement.style.setProperty(`--${value}`, theme[value]);
    }
};

export default function useTheme(
    defaultTheme = {
        ...getMuiTheme(defaultThemeSettings),
        ...defaultThemeSettings
    }
) {
    const [theme, _setTheme] = useState(getInitialTheme);

    function getInitialTheme() {
        const savedTheme = storage.getItem('theme');

        if (savedTheme) {
            const storedTheme = JSON.parse(savedTheme);

            storedTheme.values = storedTheme.mode === "light" ? lightThemeVariables : darkThemeVariables;
            storedTheme.default = {};

            let newTheme = {
                ...getMuiTheme(storedTheme),
                ...storedTheme
            };
            setCSSVariables(newTheme.values);
            return newTheme
        }

        return defaultTheme;
    }

    useEffect(
        () => {
            const themeToSave = {
                mode: theme.mode,
                values: {},
                device: theme.device,
                default: {}
            };

            setCSSVariables(theme.values);

            storage.setItem('theme', JSON.stringify(themeToSave));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [theme]
    );

    const size = useWindowSize();

    useEffect(
        () => {
            let device = "desktop";

            if (size.width < 1025 && size.width >= 800) {
                device = "tablet";
            } else if (size.width <= 799) {
                device = "mobile";
            }

            const newTheme = Object.assign({}, theme);
            newTheme.device = device;
            _setTheme(newTheme);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [size]
    );

    return {
        ...theme,
        setTheme: ({setTheme, ...theme}) => _setTheme(theme)
    };
}

import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import captnApiClient from "../sdk/CaptnApiClient";

export const DefaultSecureRoutes = [];

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
    >
        {
            props => captnApiClient.identity.isAuthenticated() ? <Component {...props} /> : <Redirect to="/login"/>
        }
    </Route>
);

export default PrivateRoute;

import React from "react";
import {Button as BootstrapButton} from "react-bootstrap";

const Button = ({variant, type, size, href, label, onClick}) => {
    return <BootstrapButton variant={variant} type={type} href={href} size={size} onClick={onClick}>
        {label}
    </BootstrapButton>;
};

export default Button;
